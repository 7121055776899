.search_form {
  ion-list-header {
    color: #acbd59;
    font-size: medium;
    font-weight: 600;
    //color: var(--search-color-titles);
  }
  ion-list-header:nth-of-type(2n) {
    //--background: var(--ion-color-primary-tint);
    --background: var(--search-color);
    //--background: #fafafa;
    --color: black;
    color: var(--search-color-titles);
  }
  ion-item:nth-of-type(2n) {
    //--background: var(--ion-color-primary-tint);
    --background: var(--search-color);
    //--background: #fafafa;
    --color: black;
  }
  //#ced4da

  ion-item {
    //--margin-bottom: 20px;
  }

  ion-button {
    --background: rgb(172,189,89);//rgb(141, 69, 148);
    color: white;
    white-space: unset;
  }

  ion-item:nth-of-type(2n) ion-button {
    --background: var(--search-color-titles);
    color: white;
    white-space: unset;
  }
}
ion-button {
    text-transform: initial;
    letter-spacing: initial;
    font-weight: bold;
    --background: white;
    color: black;
}

.alert-button-group.sc-ion-alert-md
{
    justify-content: center;
}

ion-button.selected {
    --background: #be6bc7;
    color: white;
}

ion-button.disabled {
    color: #aaa;
    font-style: italic;
}

.multi-button-select {
    margin: 10px;
    display: flex;
    flex-wrap: wrap;
    flex-flow: wrap;
    max-width: 100%;
}

.check-icon {
    margin-right: 4px;
}

.explore .icon {
    padding-right: 1em;
}


ion-button.select_all {
    --background: white;
    color: black;
    --border-color: #acbd59;
    --border-radius: 1px;
    --border-style: solid;
}

ion-button.select_all.selected {
    --background: #acbd59;
    color: white;
}

.center-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.language-modal ion-button {
    --padding-end: 0;
    --padding-start: 0;
}

ion-toolbar {
    --background: #cdd79b;
    ion-button {
        --background: transparent;
    }
}
.ion-color-purple {
    --ion-color-base: #acbd59;
    --ion-color-base-rgb: 45, 211, 111;
    --ion-color-base-contrast: #ffffff;
    --ion-color-base-contrast-rgb: 255, 255, 255;
    --ion-color-base-shade: #a552ae;
    --ion-color-base-tint: #c16ecb;
}
.home, .language-page {
    .content {
        display: flex;
        height: 100%;
        flex-direction: column;
        gap: 8px;

        align-content: flex-end;

        .main {
            flex-grow: 1;
            display: flex;
            flex-direction: column;

            .home-loading {
                background: rgba(0,0,0,0.2);
                border-radius: 10px;
            }

            .action {
                display: flex;
                flex-grow: 1;
                flex-directioN: column;
                justify-content: flex-end;
            }
        }

        .info-help{
            display: flex;
            flex-direction: row;
        }
    }

    ion-grid {
        display: flex;
        flex-flow: column;
        height: 100%;
    }

    .bottom {
        margin-top: auto;
    }

    .top, .bottom {
        width: 100%;
    }

    .message {
        background: rgba(0,0,0,0.2);
        padding: 16px;
        margin-bottom: 8px;
        border-radius: 8px;
        color: white;
        font-weight: bold;
        text-align: center;
        width: 100%;
        align-self: flex-start;
    }
    .logo {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        text-align: center;
        margin: 16px;
        align-items: center;
        img {
            max-height: 70px;
        }
    }


    .axis {
        display: flex;
        color: black;
        flex-direction: row;
        justify-content: center;
        margin-left: 3vw;
        gap: 8px;
        & > div {
            text-align: left;
        }
    }

    .darkLabel{
        color: black;
    }

    .axis.dark{
        color: white;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 8px;
        & > div {
            text-align: left;
        }
    }

    .eu-fund {
        background: white;
        margin: 16px 22px;
        display: flex;
        justify-content: center;
        gap: 1.6em;
        align-items: center;

        .span {
            flex-grow: 1;
            margin-top: auto;
            margin-bottom: auto;
        }
    }

    .eu-fund.dark
    {
        background: #495057;
        color: white;
        margin: 16px 22px;
        display: flex;
        justify-content: center;
        gap: 1.6em;
        align-items: center;

        .span {
            flex-grow: 1;
            margin-top: auto;
            margin-bottom: auto;
        }
    }

    .partners {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        margin: 16px;
        gap: 10px;
        max-height: 60px;

        > div {
            height: 50px;
            width: 50px;
            display: flex;
        }
        img {
            flex-grow: 0;
            align-self: center;
            //display: none;
            //max-height: 50px;
        }
    }
}

.home {
    ion-button {
        width: 100%;

        --padding-bottom: 16px;
        --padding-top: 16px;
        --padding-left: 16px;
        --padding-right: 16px;
    }

    ion-content {
        --background: url('../resources/LE_ISOLE_TREMITI_005.jpg') no-repeat center center / cover;
        --background-size: 100%;
        display: flex;
        --padding-bottom: 20px;
        --padding-end: 20px;
        --padding-start: 20px;
        --padding-top: 20px;
    }

    h1 {
        color: white;
    }

    .countries {
        margin-top: 8px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 4px;
    }
}

.help-section {
  padding: 8px;
}

.help-question {
  font-weight: bold;
  margin-top: 1em;
  color: #B5C46C;
}

.help-answer {

}

.gis-container {
  position: relative;
  height:100%;
  width:100%;
}
.gis-route-modal-container {

  &.collapsed {
    top: 100%;
  }
  position: absolute;
  bottom: 1em;
  left: 1em;
  right: 1em;
  top: 60%;
  z-index: 1000;

  transition: top 600ms ease-out;
}
.gis-route-modal {
  width: 100%;
  height: 100%;
  background: rgba(172,189,89,0.95);

  border-radius: 10px;
  padding: 2em;
  color: white;
  overflow-x: scroll;

  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */

  .route-container {
    display: flex;
    flex-wrap: nowrap;
    gap: 16px;
  }

  &::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
  }

  .route-icon {
    margin: 0 8px 0 0;
  }

  .route-content {
    flex-grow: 1;
  }

  .route-title {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 8px;
  }

  .route-description {
    text-align: justify;
    margin-bottom: 1em;
  }

  .route-info {
    font-size: 0.85em;
    margin-bottom: 6px;
  }

  .route-points {
    display: flex;
    flex-direction: column;
  }

  .route-point {
    flex-shrink: 0;
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 1em;
    //min-height: 80%;
    align-items: center;
    background: rgba(255,255,255,0.05);
    border-radius: 0.5em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .current {
    background: rgba(255,255,255,0.1);
  }

  .route-point-name {
    font-size: 110%;
    margin-bottom: 5px;
  }
  .route-point-icon {
    width: 16px;
    margin: 0 4px 0 0;
  }

  .route-point-content {
    flex-grow: 1;
    margin: 1em;
  }

  .route-point-description {
    font-size: 0.85em;
    text-align: justify;
  }

  .collapse {
    background: rgba(172,189,89,0.95);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    position: absolute;

    height: 24px;
    top: -24px;

    width: 50px;
    left: 50%;
    margin-left: -25px;

    padding: 4px;
    font-size: 20px;
    text-align: center;
  }
}